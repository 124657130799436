/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import 'styles/application'
import 'styles/errors_area'
import 'styles/modal'
import 'styles/style'

// turbolinksはページ遷移時、スクロール位置保存しちゃうので、それの対策
document.addEventListener('turbolinks:load', () => {
  const hash = location.hash

  if (window['NO_SCROLL_FLG']) {
    window['NO_SCROLL_FLG'] = false
    return
  }

  if (!hash) {
    setTimeout(() => {
      $(document).scrollTop(0)
    }, 200)
  } else if ($(`${hash}`).length > 0) {
    const offset = $(`${hash}`).offset().top
    setTimeout(() => {
      $('html, body').animate({ scrollTop: offset }, 600, 'swing')
    }, 200)
  }
})

// Noticeを閉じる
$(document).on('click', '.-noticeArea .close', ev => {
  const $el = $(ev.currentTarget)
  const $area = $el.closest('.-noticeArea')
  $area.fadeOut()
})
